import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PodConfirmationList } from './route-list/route-list.component';
import { PodConfirmationDetail } from './detail/detail.component';

const PodConfirmationRoutes: Routes = [
  {
    path: '', component: PodConfirmationList, data: { title: 'POD Confirmation - WARP Admin' },
  },
  {
    path: ':id', component: PodConfirmationDetail, data: { title: 'POD Confirmation - WARP Admin' },
  },
  // { path: 'sub-window', component: SubWindow, data: { title: 'POD Confirmation - WARP Admin'}},
  
];
@NgModule({
  imports: [RouterModule.forChild(PodConfirmationRoutes)],
  exports: [RouterModule]
})
export class PodConfirmationRoutingModule { }
