import { Carrier,User,Driver } from '@wearewarp/types/data-model';
import { Job } from '@wearewarp/types/data-model';
import { Component, Input, OnInit } from '@angular/core';
import { DialogService } from '@dialogs/dialog.service';
import { SendEmailRequestPod } from '../send-email-request-pod/send-email-request-pod.component';
import { PodService } from '../../pod.service';
import { BaseComponent } from '@abstract/BaseComponent';
import { Utils } from '@services/utils';
import { DateUtil } from '@services/date-utils';
import { Const } from '@const/Const';


interface JobV2 extends Job{
  completedWhenBy:{
    when: string,
    by?: User,
    byCarrier?: Carrier,
    byDriver?: Driver
  }
}
@Component({
  selector: '[pod-confirmation-header]',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class podConfirmationHeader extends BaseComponent{
  @Input() job: JobV2;
  public displayInfo: any = {};
  constructor(private podService: PodService) {
    super()
   }

  ngOnChanges(): void {
    this.buildDisplayInfo()
  }

  public buildDisplayInfo(){
    if(!this.job) return;
    const displayInfo = {
      jobId: this.job.id,
      jobCode: this.getJobCode(this.job),
      listClientName: this.getListClientName(this.job),
      completedWhen: this.formatDate(this.job?.completedWhenBy?.when),
      completedBy: this.getCompleteBy(this.job?.completedWhenBy)
    }
    this.displayInfo = displayInfo;
  }

  public getJobCode(job: Job){
    if(!job) return '';
    return job?.code ?? '';
  }

  public getListClientName(job: Job){
    if(!job) return ''
    const clients = job?.clients;
    if(!clients || !clients?.length) return '';
    return clients.map(client => client?.name).join(', ')
  }

  public openSendEmailDialog(){
    const job = this.podService.getSelectedJob();
    DialogService.openFormDialog1(SendEmailRequestPod, {
      nzComponentParams: {
        job: job,
        closeOnSuccess: true,
        updateSuccess: (resp) => {
          this.showSuccess('Send email to carrier success.');
          this.refreshPodChange()
        },
      },
      nzWidth: 646,
      nzClassName: 'model-no-padding',
      nzCentered: true,
      nzClosable: true,
      nzTitle: 'Request POD email',
    })
  }

  getLastSentRequest(item) {
    if(!item) return false;
    let historyArr = item?.historySendRequestPOD ?? [];
    if (Utils.isArrayNotEmpty(historyArr)) {
      let lastTime = historyArr[historyArr.length - 1]?.when ?? '';
       return DateUtil.format(lastTime, Const.FORMAT_GUI_DATETIME)
    }
    return false;
  }

  public formatDate(date){
    if(!date) return 'N/A';
    return DateUtil.formatDate(date, Const.FORMAT_GUI_DATE);
  }
  public getCompleteBy(completedWhenBy: any){
    if(!completedWhenBy) return 'N/A';
    if(completedWhenBy?.byDriver){
      let driver = completedWhenBy?.byDriver;
      return `Driver ${driver?.firstName ||''} ${driver?.lastName ||''}`;
    }
    if(completedWhenBy?.byCarrier){
      let carrier = completedWhenBy?.byCarrier;
      return `Driver ${carrier?.basicInfo?.name ||''}`
    }
    if(completedWhenBy?.by){
      let user = completedWhenBy?.by;
      return user?.fullName || `${user?.firstName || ''} ${user?.lastName || ''}`;
    }
    return 'N/A'
  }

  private refreshPodChange(){
    let jobId = this.podService.getSelectedJob()?.id;
    let stopId = this.podService.getSelectedStop().getId();
    this.podService.fetchRoute(jobId, stopId);
  }
}
