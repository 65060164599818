import { Component, Input, OnInit } from '@angular/core';
import { StringUUID } from '@wearewarp/types';
import { Const } from '@wearewarp/universal-libs';
import { PodService } from '../../pod.service';
import { BaseComponent } from '@abstract/BaseComponent';
import StopEntity from '../../Entity/StopEntity';
interface DisplayStop{
  id: StringUUID,
  type: string,
  index: number,
  needConfirm: boolean
}
@Component({
  selector: '[pod-confirmation-stop-list]',
  templateUrl: './stop-list.component.html',
  styleUrls: ['./stop-list.component.scss']
})
export class PodConfirmationStopList  extends BaseComponent{
  @Input() stopList: DisplayStop[] = [];
  public selected: StringUUID = '';
  public selectedStop$;
  constructor(private podService: PodService) {
    super();
    this.selectedStop$ = this.podService.onSelectedStop;
   }

  ngOnInit(): void {
    this.subscription.add(
      this.selectedStop$.subscribe(
        (stop: StopEntity) => {
          if(!stop) return;
          this.selected = stop.getId();
        }
      )
    )
  }

  public shouldShowNeedConfirmIcon(item: DisplayStop){
    return item.needConfirm;
  }

  public getTypeText(item: DisplayStop){
    return item.type === Const.TaskType.PICKUP? 'PU':'DO';
  }

  selectStop(id: StringUUID){
    if(id === this.selected) return;
    this.podService.setSelectedStop(id);
  }
}
