import { Component, Input, OnInit } from '@angular/core';
import { DialogService } from '@dialogs/dialog.service';
import { AttachedFile } from '@wearewarp/types/data-model';
import { PDFDocumentProxy } from 'pdfjs-dist';

@Component({
  selector: 'pdf-pod',
  templateUrl: './pdf-pod.component.html',
  styleUrls: ['./pdf-pod.component.scss']
})
export class PdfPod implements OnInit {
  @Input() pod: AttachedFile
  @Input() set src(value){
    this._value = value;
    this.fetchSrc()
  }
  private _value;
  public fileUrl: string;
  public isLoading: boolean = true;
  zoom = 1;
  rotation = 0;
  constructor() { }

  ngOnInit(): void {
  }
  async fetchSrc() {
    if(typeof this._value == 'string') {
      this.fileUrl = this._value;
      this.isLoading = false
      return;
    }
    // this.isLoading = true;
    this.fileUrl = await this._value();
  }
  loadedPdfPod(pdf: PDFDocumentProxy){
    this.isLoading = false;
  }

  zoomIn(){
    this.zoom += 0.1;
  }
  zoomOut(){
    if(this.zoom <0.2) return;
    this.zoom -= 0.1;
  }
  rotateRight(){
    this.rotation+= 90;
  }
  rotateLeft(){
    this.rotation -= 90;
  }
  viewPodItem() {
    DialogService.previewPdfPodTransparent([this.pod]);
  }
}
