import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseFormItem } from '@app/admin/base/form-item';
import { QuickUploadPod } from '@app/admin/finance-v2/quick-upload-pod';
import { Const } from '@const/Const';
import { DateUtil } from '@services/date-utils';

interface CountTab {
  needPod?: number,
  needPodConfirm?: number
}

@Component({
  selector: '[pod-confirmation-list-filter]',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class PodConfirmationListFilter extends BaseFormItem {
  @Input() set countSum(value: CountTab) {
    if (typeof value.needPod == 'number') {
      this.filterTabs[0].total = value.needPod;
    }
    if (typeof value.needPodConfirm == 'number') {
      this.filterTabs[1].total = value.needPodConfirm;
    }
  }
  filterTabs = [
    {
      key: 'pod_needed',
      title: 'PODs upload needed',
      total: 0,
    },
    {
      key: 'need_review',
      title: 'PODs confirm needed',
      total: 0,
    }
  ];
  filterTabsIndex = {pod_needed: 0, need_review: 1};
  @Output() onReload: EventEmitter<any> = new EventEmitter<any>();
  @Output() onFilterTabChanged: EventEmitter<number> = new EventEmitter<number>();
  public condition: any = {};
  public searchKeyword: string = null;
  public sort: any = undefined;
  public page: number = 1;
  protected formGroupDeclaration: FormGroupDeclaration = PodConfirmationListFilter.declaration;

  constructor(protected activatedRoute: ActivatedRoute) {
    super();
    this.activatedRoute.queryParams.subscribe(p => {
      if (p.filter) {
        try {
          this.condition = JSON.parse(p.filter);
        } catch {
          this.condition = {};
        }
      }
      this.sort = p.sort || undefined;
      this.searchKeyword = p.search;
      this.page = p.page || 1;
      let tab = p.tab || this.filterTabs[0].key;
      this._selectedTabIndex = this.filterTabsIndex[tab] || 0;
      this.onFilterTabChanged.emit(this._selectedTabIndex);
    });
    this.onRefresh();
  }

  public static get declaration(): FormGroupDeclaration {
    return {
      searchKeyword: {label: 'Keyword', placeHolder: '', notAcceptEmpty: true},
    };
  }

  private _selectedTabIndex = 0;

  get selectedTabIndex() {
    return this._selectedTabIndex;
  }

  set selectedTabIndex(value) {
    if (this._selectedTabIndex == value) {
      return;
    }
    this.sort = undefined;
    this._selectedTabIndex = value;
    this.onFilterTabChanged.emit(value);
    this.page = 1;
    this.condition = {};
    this.formInput.reset();
    this.onRefresh();
  }

  ngOnInit(): void {
    this.model = Object.assign({}, this.condition, {search: this.searchKeyword});
    super.ngOnInit();
  }

  onChange(e, name) {
    this.page = 1
    if (e)
      this.condition[name] = e
    else
      delete this.condition[name]
    this.loadData()
  }

  public isFetchingCarrier = false;
  listCarriers = [];
  
  isSelectPodNeededTab() {
    return this._selectedTabIndex == this.filterTabsIndex.pod_needed;
  }

  doSearch(s) {
    this.searchKeyword = s;
    this.page = 1;
    this.loadData();
  }

  onRefresh() {
    this.onReload.emit();
    if (this.sort) {
      this.sort = null;
    }
    this.loadData();
  }

  loadData() {
    let query = {page: this.page || 1, loaded: Date.now()};
    if (this.searchKeyword) {
      query['search'] = this.searchKeyword;
    }
    this.reformatConditionObject();
    query['tab'] = this.filterTabs[this._selectedTabIndex].key;
    query['filter'] = JSON.stringify(this.condition);
    if (this.sort) {
      query['sort'] = this.sort;
    }
    this.routeWithQueryUrl(query);
  }

  onChangeFilter(event, key) {
    this.page = 1;
    if (key == 'fromDate' && event) {
      this.condition[key] = DateUtil.getDateFrom(DateUtil.isoDate(event));
    } else if (key == 'toDate' && event) {
      this.condition[key] = DateUtil.getDateTo(DateUtil.isoDate(event));
    }else if (key) {
      this.condition[key] = event;
    } else {
      delete this.condition[key];
    }
    this.reformatConditionObject();
    this.loadData();
  }

  private reformatConditionObject() {
    if (this.condition.carrierId == null) {
      delete this.condition.carrierId;
    }
    if (this.condition.fromDate == null) {
      delete this.condition.fromDate;
    }
    if (this.condition.toDate == null) {
      delete this.condition.toDate;
    }
  }

  openQuickUploadPOD(){
    this.modalService.create({
      nzContent: QuickUploadPod,
      nzTitle: null,
      nzFooter: null,
      nzStyle:{
        top: '10px',
      },
      nzWidth: '100%',
    })
  }

}
