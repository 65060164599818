<div *ngIf="hasData()">
    <div class="stop">
        <div class="stop-level" id="stop-{{displayInfo.id}}">
            <div class="info">
                <div class="address">
                    <span>{{displayInfo.locationName}}, {{displayInfo.addr}}</span>
                </div>
                <div>
                    Arrived on <span>{{displayInfo.actualArrived}}</span>
                </div>
                <div class="refNum">
                    Ref no: 
                    <span *ngIf="!isShowRef()">N/A</span>
                    <ng-container *ngIf="isShowRef()" >
                        <nz-tag *ngFor="let ref of displayInfo.refNums" [nzColor]="'rgba(51, 65, 85, 0.12)'" style="color: #191A23; font-size: 12px; font-weight: 400; max-width: 440px; overflow: hidden;white-space: nowrap; text-overflow: ellipsis;" [nzTooltipTitle]="ref" nzTooltipPlacement="bottom" nz-tooltip>{{ ref }}</nz-tag>
                    </ng-container>
                </div>
            </div>
            <div *ngIf="displayInfo?.tasks?.length > 1" class="pods" list-pod-pod-confirmation [pods]="displayInfo.podStopLevel"></div>
        </div>
        <div class="task-container">
            <ng-container *ngFor="let task of displayInfo.tasks" >
                <div class="task-level" task-pod-confirmation [taskInfo]="task" id="task-{{task?.taskId}}"></div>
            </ng-container>
        </div>
    </div>
</div>
<div *ngIf="!hasData()">
    <nz-skeleton [nzActive]="true" [nzParagraph]="{ rows: 10, width: ['100%', '100%', '100%', '100%','100%','100%', '100%', '100%','100%','100%'] }"></nz-skeleton>
</div>
