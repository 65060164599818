<div class="breadcrumb">
    <nz-breadcrumb>
        <nz-breadcrumb-item><a [routerLink]="[routeAdminPodConfirmationList]">POD Confirmation</a></nz-breadcrumb-item>
        <nz-breadcrumb-item>{{displayInfo?.jobCode}}
            <a [routerLink]="[routeAdminDispatchList, displayInfo?.jobId]" target="_blank" nz-tooltip
            nzTooltipTitle="Go to dispatch"><i nz-icon nzType="rocket" nzTheme="outline" style="margin-left: 5px;"></i></a>
        </nz-breadcrumb-item>
    </nz-breadcrumb>
</div>
<div class="header-content">
    <div class="content">
        <div style="display: flex; justify-content: start; gap: 8px;">
            <div class="jobcode">{{displayInfo?.jobCode}}</div>
            <div class="text-italic">Completed on <span>{{displayInfo?.completedWhen}}</span> by <span>{{displayInfo?.completedBy}}</span></div>
        </div>
        <div class="customer">
            Customer:
            <span>{{displayInfo?.listClientName}}</span>
        </div>
    </div>
    <div class="send-request">
        <ng-container *ngIf="!getLastSentRequest(job)">
            <button nz-button class="send-email-button" (click)="openSendEmailDialog()">
                <span nz-icon nzType="send" nzTheme="outline"></span>
                <span>Request POD</span>
            </button>
        </ng-container>
        <ng-container *ngIf="getLastSentRequest(job)">
            <div class="request-sent">
                Last sent request on 
                <span>{{getLastSentRequest(job)}}</span>
            </div>
        </ng-container>
    </div>
</div>
