import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NotesComponentEventFetchDataDone, NotesComponentEventItemAdded, NotesComponentEventItemRemoved } from '@app/admin/components/notes/interface';
import { BaseDrawer } from '@app/drawers/base-drawer';
import { Const } from '@const/Const';

@Component({
  selector: 'app-issue',
  templateUrl: './issue.component.html',
  styleUrls: ['./issue.component.scss']
})
export class Issue extends BaseDrawer {
  @Input() podItem: any;
  @Input() refreshPodChange: any;
  private hasChange: boolean = false;
  constructor() {
    super();
  }

  ngOnInit(): void {
    if(!this.podItem) return;
  }


  public onNoteFetchingDone(podItem, data: NotesComponentEventFetchDataDone) {
    const before = podItem.hasIssue;
    const after = data.countTotal > 0;
    if (before != after) {
      // Âm thầm correct
      podItem.hasIssue = after;
      this.correctPodIssue(podItem);
    }
  }

  private correctPodIssue(podItem) {
    const url = Const.APIV2(`pods/${podItem._id}/correct_issue_status`);
    this.api.POST(url).subscribe();
  }

  public onNoteItemAdded(podItem, data: NotesComponentEventItemAdded) {
    this.hasChange = true;
    if (!podItem.hasIssue) {
      podItem.hasIssue = true;
    }
  }

  public onNoteItemRemoved(podItem, data: NotesComponentEventItemRemoved) {
    this.hasChange = true;
    if (data.countBeforeRemove <= 1 && podItem.hasIssue) {
      podItem.hasIssue = false;
    }
  }

  closePopup() {
    if(this.hasChange) this.refreshPodChange();
    this.nzDrawer.close()
  }
}
