import { DeliveryInfo, Task } from "@wearewarp/types/data-model";
import { Const } from "@wearewarp/universal-libs";
import { PodService } from "../pod.service";
import { getInjector } from "@services/injector";
import { MasterData } from "@services/master.data";

export default class TaskEntity {
  private data: Task;
  constructor(private podService: PodService) {}
  init(task: Task) {
    this.data = task;
    return this;
  }

  getId() {
    return this.data.id;
  }

  getStatus() {
    return this.data.status || Const.TaskStatus.created
  }

  getArrivedTime() {
    if (![Const.TaskStatus.arrived, Const.TaskStatus.succeeded, Const.TaskStatus.failed].includes(<any>this.getStatus())) return;
    const log = this.data.statusChangeLog[Const.TaskStatus.arrived]
    let changeWhen = log?.changeWhen ?? log?.when // fix bug changeWhen null
    //trường hợp không có thông tin arrived time (do đổi status trực tiếp sang completed),
    //sẽ lấy thời gian depart để hiển thị cho arrived. Coi như đến rồi đi luôn.
    if (!changeWhen) return this.getDepartedTime();
    return changeWhen
  }

  getDepartedTime() {
    if (![Const.TaskStatus.succeeded, Const.TaskStatus.failed].includes(<any>this.getStatus())) return;
    //nếu status hiện tại là failed. thì lấy departed time là thời gian đổi sang failed
    let log;
    switch (this.getStatus()) {
      case Const.TaskStatus.failed:
        log = this.data.statusChangeLog[Const.TaskStatus.failed]
        break;
      case Const.TaskStatus.succeeded:
        log = this.data.statusChangeLog[Const.TaskStatus.succeeded]
        break;
      default:
    }
    return log?.changeWhen ?? log?.when
  }
  getETA() {
    return this.data.etaTime
  }

  getShipmentId() {
    return this.data.shipmentId
  }

  getType() {
    return this.data.type
  }

  getShipment() {
    return this.podService.getShipmentById(this.getShipmentId())
  }

  getTotalPod() {
    return this.data.podUploadFiles?.length ?? 0;
  }


  getPodUploadFiles() {
    return this.data.podUploadFiles || [];
  }

  getDelay() {
    const log = this.data.statusChangeLog?.[Const.TaskStatus.arrived];
    if (log?.info?.delayCodeId) {
      return {
        delayCodeId: log.info.delayCodeId,
        note: log.info.delayNote
      }
    } else {
      return this.data.delay; // dùng cho dữ liệu cũ
    }
  }

  isExistsReturn() {
    return this.data.returnTaskId ? true : false;
  }

  getRefNums() {
    return this.data.info?.refNums || [];
  }

  getFailedReason() {
    const log = this.data.statusChangeLog?.[Const.TaskStatus.failed];
    return log?.info?.failed_reason;
  }


  getLocationName() {
    return this.data.info.locationName || "No Name"
  }

  getAddressText() {
    return MasterData.getAddressText(this.data.info.addr)
  }
  toJSON() {
    return {
      ...this.data
    }
  }
}