import { BaseComponent } from '@abstract/BaseComponent';
import { Component, OnInit } from '@angular/core';
import { PodService } from '../pod.service';
import { ActivatedRoute } from '@angular/router';
import { Job, Stop } from '@wearewarp/types/data-model';
import StopEntity from '../Entity/StopEntity';

@Component({
  selector: 'pod-confirmation-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})
export class PodConfirmationDetail extends BaseComponent {

  public job: Job;
  public isLoading: boolean = true;;
  public loading$;
  public job$;
  public displayInfo;
  constructor(private podService: PodService, protected activatedRoute: ActivatedRoute) { 
    super();
    this.loading$ = this.podService.loading;
    this.job$ = this.podService.job;
  }

  ngOnInit(): void {
    this.subscription.add(
      this.job$.subscribe((job: Job | null) => {
        if(job) {
          this.job = job;
          this.buildDisplayInfo();
        }
      })
    );
    this.subscription.add(
      this.loading$.subscribe((data: boolean) => {
        this.isLoading = data;
      })
    )
  }

  protected handleNavigationEnd(url: string, prevQueryParam: any): void {
    const id= this.getIdForDetailComponent(this.activatedRoute);
    this.podService.fetchRoute(id);
  }

  private buildDisplayInfo(){
    const displayInfo = {
      listStop: this.getListStops()
    };
    this.displayInfo = displayInfo;
  }

  public getListStops(){
    const stops = this.podService.getStops();
    if(!stops.length) return [];
    const displayStops = stops.map((stop: StopEntity, index) => {
      let pods = this.podService.getPodByStop(stop.getId());
      let needConfirm = false;
      for(let pod of pods){
        if(!pod?.podConfirmed?.when){
          needConfirm = true;
          break;
        }
      }
      return {
        id: stop?.getId(),
        type: stop?.getType(),
        index: index + 1,
        needConfirm: needConfirm
      }
    })
    return displayStops;
  }
  ngOnDestroy(){
    this.podService.clearOldData()
  }
}
