<div>
  <nz-checkbox-wrapper style="width: 450px;">
    <div nz-row class="mb-10">
      <nz-radio-group [(ngModel)]="podLevel" nzButtonStyle="solid" (ngModelChange)="initChecked()">
        <label nz-radio [nzValue]="0">Stop Level</label>
        <div *ngIf="isStopLevel()" class="options">
          <div nz-row>
            <div nz-col nzSpan="24">
              <label nz-checkbox [nzValue]="checkAllStops" [(ngModel)]="checkAllStops"
                (ngModelChange)="onCheckAllPodsStopLevel($event)" [nzIndeterminate]="indeterminateStops">Check All</label>
            </div>
          </div>
          <div nz-row>
            <div nz-col nzSpan="12">
              <label class="no-margin" *ngFor="let stop of stops" nz-checkbox [(ngModel)]="selectedStops[stop.id]" (ngModelChange)="refreshCheckAllPods()">
                Stop {{stop.index}}-{{stop.type}}</label>
            </div>
          </div>
        </div>
        <label nz-radio [nzValue]="1">Shipment Level</label>
        <div *ngIf="isShipmentLevel()" class="options">
          <div nz-row>
            <div nz-col nzSpan="24">
              <label nz-checkbox [nzValue]="checkedAllPods" [(ngModel)]="checkedAllPods"
                (ngModelChange)="onCheckAllPodsShipmentLevel($event)" [nzIndeterminate]="indeterminatePods">Check All</label>
            </div>
          </div>
          <div nz-row>
            <div nz-col nzSpan="12">
              <label class="no-margin" *ngFor="let task of getTask('PICKUP')" nz-checkbox [(ngModel)]="selecteds[task.id]" (ngModelChange)="refreshCheckAllPods()">
                {{task.type}} {{showShipmentWarpId(task?.shipment?.warpId)}}</label>
            </div>
            <div nz-col nzSpan="12">
              <label class="no-margin" *ngFor="let task of getTask('DROPOFF')" nz-checkbox [(ngModel)]="selecteds[task.id]" (ngModelChange)="refreshCheckAllPods()">
                {{task.type}} {{showShipmentWarpId(task?.shipment?.warpId)}}</label>
            </div>
            <div nz-col nzSpan="24">
              <label class="no-margin" *ngFor="let task of getTask('RETURN')" nz-checkbox [(ngModel)]="selecteds[task.id]" (ngModelChange)="refreshCheckAllPods()">
                {{task.type}} {{showShipmentWarpId(task?.shipment?.warpId)}}</label>
            </div>
          </div>
        </div>
      </nz-radio-group>
    </div>
    
    
  </nz-checkbox-wrapper>
  <div class="button-submit">
    <button nz-button nzType="primary" [nzLoading]="(isSubmitting)" (click)="onSubmit()"
      [disabled]="shouldDisableAssignButton()">{{getBtnText()}}</button>
  </div>
</div>