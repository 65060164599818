<div *ngIf="displayInfo" class="container">
    <div class="item">
        Email will be sent to <span class="email">{{displayInfo?.email}}</span>
    </div>
    <div class="item">
        <div class="label">
            Subject<span class="label-mark-required"></span>
        </div>
        <input nz-input [(ngModel)]="subject" disabled>
    </div>
    <div class="item">
        <div class="label">
            Content<span class="label-mark-required"></span>
        </div>
        <textarea nz-input rows="4" [(ngModel)]="message"></textarea>
    </div>
    <div class="item">
        <div class="label">Available variable</div>
        <div *ngFor="let item of listAcceptedVariable" style="margin-bottom: 5px;">
            <nz-tag><span class="tag">{{getTextVarriable(item)}}</span></nz-tag>
        </div>
    </div>
</div>
<div *nzModalFooter>
    <button nz-button nzType="default" (click)="onCancel()">Cancel</button>
    <button nz-button nzType="primary" (click)="onOk()" [nzLoading]="onProgress">Send Email</button>
</div> 