import { Component, OnInit } from '@angular/core';
import { BaseList } from '@app/admin/base/list';
import { Const } from '@const/Const';
import { DateUtil } from '@services/date-utils';
import { InputHelper } from '@services/input-helper';
import { Utils } from '@services/utils';
import { ModalHelper } from '@wearewarp/ng-antd';
import { ResponseAdminFinRouteListPODConfirmationItem } from '@wearewarp/types/rest-api/admin/fin';

enum TabFilter {
  needPod = 'needPod',
  needPodConfirm = 'needPodConfirm',
}

interface CountTab {
  needPod?: number,
  needPodConfirm?: number,
}

@Component({
  selector: 'pod-confirmation-list',
  templateUrl: './route-list.component.html',
  styleUrls: ['./route-list.component.scss','../../list.scss']
})
export class PodConfirmationList extends BaseList<ResponseAdminFinRouteListPODConfirmationItem> {
  private _selectedTabIndex = 0;
  public countSum: CountTab = {}

  constructor(private modalHelper: ModalHelper) {
    super();
  }

  get currentTabFilter(): TabFilter {
    switch (this._selectedTabIndex) {
      case 0: return TabFilter.needPod;
      case 1: return TabFilter.needPodConfirm;
    }
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  protected handleNavigationEnd(url: string, prevQueryParam: any): void {
    super.handleNavigationEnd(url, prevQueryParam);
    this.countAll();
  }

  onFilterTabChanged(value) {
    this._selectedTabIndex = value;
  }

  getRouterLinkDetail(item: ResponseAdminFinRouteListPODConfirmationItem) {
    return `${Const.routeAdminPODNeedConfirm}/${item.route.id}`;
  }

  public getCompletedDate(item: ResponseAdminFinRouteListPODConfirmationItem){
    return DateUtil.formatDate(item?.route?.completedWhenBy?.when, Const.FORMAT_GUI_DATE)
  }

  protected getApiUrl(): string {
    return this.getApiByTab(this.currentTabFilter);
  }

  protected onGetDataSucceeded(resp: any): void {
    if (resp?.data?.total) {
      this.updateCountSum(this.currentTabFilter, resp.data.total);
    }
  }

  private countAll() {
    this.countForTab(TabFilter.needPod);
    this.countForTab(TabFilter.needPodConfirm);
  }

  private countForTab(tab: TabFilter) {
    const params = this.prepareParamGetList();
    const url = Utils.appendQueryStringIntoUrl(`${this.getApiByTab(tab)}?countOnly=true`, params);
    this.api.GET(url).subscribe(
      resp => {
        if (resp?.data?.total != null) {
          this.updateCountSum(tab, resp.data.total);
        }
      }, err => {
        this.showErr(err);
      }
    );
  }

  private updateCountSum(tab: TabFilter, value: number) {
    this.countSum = {...this.countSum, ...{[tab]: value}};
  }

  private getApiByTab(tab: TabFilter) {
    switch (tab) {
      case TabFilter.needPod: return Const.APIURI_POD_CONFIRMATION_V2('list_need_pod');
      case TabFilter.needPodConfirm: return Const.APIURI_POD_CONFIRMATION_V2('list_need_pod_confirm');
    }
  }

  public sortCompleteDate = (a: ResponseAdminFinRouteListPODConfirmationItem, b: ResponseAdminFinRouteListPODConfirmationItem) => {
    let dateA = a?.route?.completedWhenBy?.when ? new Date(a?.route?.completedWhenBy?.when) : new Date();
    let dateB = b?.route?.completedWhenBy?.when ? new Date(b?.route?.completedWhenBy?.when) : new Date();
    return dateA.getTime() - dateB.getTime();
  }

}
