import { BaseComponent } from "@abstract/BaseComponent";
import { Component, Input } from "@angular/core";

@Component({
    selector: 'img-lazy',
    templateUrl: './index.html'
  })
  export class ImageLazy extends BaseComponent{

    @Input() set src(value){
        this._value = value;
        this.fetchUrl()
    }
    get src(){
        return this._value;
    }
    private _value;
    public fileUrl: string;

    private async fetchUrl(){
        if(typeof this._value == 'string') {
            this.fileUrl = this._value;
            return;
        }
        // if(typeof this._value != 'function') return;
        this.fileUrl = await this._value();
    }
  }