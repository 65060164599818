import { Component, Input, OnInit } from '@angular/core';
import { Const } from '@const/Const';
import { BaseFormDialog1 } from '@dialogs/base-form-dlg1';
import { environment } from '@env/environment';
import { Job } from '@wearewarp/types/data-model';
interface SendEmailRequestPodPayLoad{
  loadCode: string,
  contactName: string,
  contactEmail: string,
  jobId: string
}
@Component({
  selector: 'app-send-email-request-pod',
  templateUrl: './send-email-request-pod.component.html',
  styleUrls: ['./send-email-request-pod.component.scss']
})
export class SendEmailRequestPod extends BaseFormDialog1 {
  @Input() job: Job;
  public listAcceptedVariable = ['contactName', 'loadCode'];
  public message = this.getDefaultMessage();
  //tam thoi disable do api khong cho custom subject
  public subject = this.getSubject();
  public displayInfo: any;
  public isLoading: boolean = false;
  constructor() {
    super()
   }

  ngOnInit(): void {
    this.buildDisplayInfo()
  }

  public buildDisplayInfo(){
    if(!this.job) return;
    const displayInfo = {
      email: (this.job?.carrier?.basicInfo?.contacts || []).map(it => it?.email).join(', '),
      variable: this.listAcceptedVariable,
    }
    this.displayInfo = displayInfo;
  }

  public getSubject(){
    return 'POD Request';
  }

  getDefaultMessage() {
    let defaultMsg = 'Dear ${ contactName },\nPlease send the PODs and invoice(s) for the Route ${ loadCode } to process your payment.\nThank you,';
    return defaultMsg;
  }

  onValidateMsgVariable(str: string): boolean {
    let ok: boolean = true;
    let errVar: string = '';
    let msg = str.replace(/\$\{.+?\}/g, (match) => {
      let len = match.length;
      let fieldName = match.slice(2, len - 1).trim();
      if (!this.listAcceptedVariable.includes(fieldName)) {
        ok = false;
        errVar = fieldName;
      }
      return '';
    })
    if (!ok) {
      this.showErr(`Not accept '${errVar}' variable`)
      return false;
    }
    return true;
  }

  async handleSendingEmail() {
    let checkErr: boolean = this.onValidateMsgVariable(this.message);
    if (!checkErr) return;

  }
  public getTextVarriable(variable: string){
    return '${ ' + variable + ' }'
  }

  public getRequestParams(){
    let ret: SendEmailRequestPodPayLoad[] = [];
    let jobCode = this.job?.code || '';
    let contactName = this.job?.carrier?.basicInfo?.name || '';
    for(let contact of this.job?.carrier?.basicInfo?.contacts || []){
      let obj: SendEmailRequestPodPayLoad = {
        loadCode: jobCode,
        contactName: contactName,
        contactEmail: contact.email,
        jobId: this.job?.id
      };
      ret.push(obj)
    }
    return ret
  }

  public onOk(){
    let body = this.getRequestParams();
    this.startProgress();
    let url = `${environment.backendUrl}/${Const.APIV2(Const.APIURI_CARRIERS)}/request-route-pod`;
    let params = {
      message: this.message ?? '',
      listEmailSending: body
    }
    this.api.POST(url, params).subscribe(
      (resp) => {
        this.stopProgress();
        this.onUpdateSuccess(resp)
      },
      (err) => {
        this.showErr("Fail to send email to carrier.");
        this.stopProgress();
      }
    )
  }

  public onCancel(){
    this.closeDialog()
  }
  //override bỏ notification của base
  protected onUpdateSuccess(resp) {
    this.updateSuccess(resp);
    if (this.closeOnSuccess) {
      this.closeDialog();
    }
  }
}
