import { Component, OnInit } from '@angular/core';
import { PODLocalFile, PodService } from '../../pod.service';
import { BaseComponent } from '@abstract/BaseComponent';
import StopEntity from '../../Entity/StopEntity';
import { DateUtil } from '@services/date-utils';
import TaskEntity from '../../Entity/TaskEntity';
import { WarpId } from '@wearewarp/universal-libs';
import { Utils } from '@services/utils';
import { Const } from '@const/Const';

@Component({
  selector: '[pod-confirmation-stop-detail]',
  templateUrl: './stop-detail.component.html',
  styleUrls: ['./stop-detail.component.scss']
})
export class PodConfirmationStopDetail extends BaseComponent{

  public stop: StopEntity;
  public podArray: PODLocalFile[];
  public isLoading: boolean = true;
  public displayInfo: any;

  constructor(private podService: PodService) {
    super()
   }

  ngOnInit(): void {
    //stop change
    this.subscription.add(
      this.podService.onSelectedStop.subscribe(
        (stop: StopEntity | null) => {
          if(!stop) return;
          this.stop = stop;
          this.podArray = this.podService.getPodArray();
          this.buildDisplayInfo();
        }
      )
    );
    //loading
    this.subscription.add(
      this.podService.loading.subscribe(
        (loading: boolean) => {
          this.isLoading = loading;
        }
      )
    )

    //POD change
    this.subscription.add(
      this.podService.selectedPod.subscribe(
        (data: PODLocalFile | null) => {
          if(!data) return;
          let focusElement : HTMLElement;
          if(data?.taskId){
            focusElement = document.getElementById(`task-${data.taskId}`);
          }else{
            focusElement = document.getElementById(`stop-${this.stop.getId()}`);
          }
          if(focusElement) this.scrollToElement(focusElement, { behavior: 'smooth' });
        }
      )
    )
  }

  private buildDisplayInfo(){
    const displayInfo = {
      id: this.stop.getId(),
      podStopLevel: this.getPODStopLevel(this.podArray),
      addr: this.stop.getAddressText(),
      locationName: this.stop.getLocationName(),
      deliveryInfo: this.stop.getDeliveryInfo(),
      actualArrived: this.getDisplayTime(this.stop.getArrivedTime()),
      refNums: this.stop.getRefNums(),
      isShowRefNumsFull: this.isShowRefNumsFull(),
      refNums_Short: this.getRefNums_Short(),
      refNums_More: this.getMoreRefNums(),
      tasks: this.buildTaskDisplayInfo()
    }
    this.displayInfo = displayInfo;
  }

  private getPODStopLevel(podArray: PODLocalFile[]){
    return podArray.filter(it => !it?.taskId)
  }

  private getDisplayTime(time) {
    return DateUtil.displayLocalTime(time,
      {
        timezone: this.stop.getTimezone(),
        format: 'MM/DD/YY h:mm A'
      }
    )
  }

  private isShowRefNumsFull() {
    let refNums = this.stop.getRefNums() || [];
    return refNums.length <= 2;
  }

  private getRefNums_Short() {
    let refNums = this.stop.getRefNums() || [];
    if (refNums.length <= 2) {
      return this.stop.getRefNums();
    } else {
      return [refNums[0],refNums[1]];
    }
  }

  private getMoreRefNums() {
    let refNums = this.stop.getRefNums() || [];
    if (refNums.length > 2) {
      return refNums.length-2;
    }
  }

  public buildTaskDisplayInfo(){
    const tasks = this.stop.getTasks();
    const routeTasks = this.podService.getTaskEntities();
    const displayInfo = [];
    for(let task of tasks){
      const obj: any = {};
      obj.taskId = task.getId();
      obj.shipmentWarpIdText = this.getWarpIdText(task);
      obj.shipmentWarpId = task.getShipment().getCode();
      obj.refNums = task.getRefNums();
      obj.pickupAddressText = task.getAddressText();
      obj.pickupLocation = task.getLocationName();
      let dropoffCorrespondTask = routeTasks.find(it => (it.getShipmentId() === task.getShipmentId() && it.getId() !== task.getId()));
      if(dropoffCorrespondTask){
        obj.dropoffAddressText = dropoffCorrespondTask.getAddressText();
        obj.dropoffLocation = dropoffCorrespondTask.getLocationName();
        dropoffCorrespondTask.getRefNums().forEach(it => {
          if(!obj.refNums.includes(it)) obj.refNums.push(it)
        })
      };
      obj.pods = this.podArray.filter(it => it?.taskId === task.getId());
      let shipmentEntity = task.getShipment();
      obj.orderRefNums = shipmentEntity.getOrderRefs();
      obj.shipmentTransitType = shipmentEntity.getShipmentTransitType();
      obj.client = shipmentEntity.getClient();
      obj.routerLink = this.getRouterLink(task)
      displayInfo.push(obj);;
    }
    
    return displayInfo;
  }

  isShowRef(): boolean{
    return this.displayInfo.refNums.length > 0;
  }

  public getWarpIdText(task: TaskEntity) {
    return task.getShipment().getCodeText();
    // return WarpId.showShipment(task.getShipment()?.getWarpId());
  }

  getRouterLink(item) {
    item = item?.getShipment()?.toJSON()
    if (item?.shipmentTransitType == Const.ShipmentTransitType.leg) {
      if (item?.parentId) {
        // Nếu đã có parentId rồi thì cho đến đúng màn luôn
        return [Const.routeAdminOrderList, item.parentId, 'tracking-items'];
      } else {
        // Nếu chưa có parentId thì cho đi qua 1 màn trung gian để fetch dữ liệu trước rồi redirect sau
        return [Const.routeAdminOrderList, 'legs', item.id];
      }
    }
    if (item?.orderId) {
      return [Const.routeAdminOrderList, item.orderId];
    }
    return [Const.routeAdminOrderList];
  }

  public hasData(){
    return Utils.isObjectNotEmpty(this.displayInfo)
  }

}
