<form [formGroup]="formInput" nz-form (keydown.enter)="$event.preventDefault()" style="margin: 0px; padding: 0px;">
    <div style="width: 100%">
      <div style="display: flex; justify-content: space-between;align-items: center; width: 100%; padding-left: 4px">
        <nz-tabset [(nzSelectedIndex)]="selectedTabIndex" [nzSize]="'small'" nzAnimated="false" style="width: 100%" type="card">
          <ng-container *ngFor="let tab of filterTabs">
            <nz-tab [nzTitle]="titleTemplate" style="font-weight: 600"></nz-tab>
            <ng-template #titleTemplate>
              <nz-badge [nzCount]="tab?.total" [nzOverflowCount]="99999" nzShowZero [nzStyle]="{ backgroundColor: '#1b1ac4'}">
                <span>{{tab.title}}</span>
              </nz-badge>
            </ng-template>
          </ng-container>
        </nz-tabset>
        <div style="display: flex;align-items: center;">
        </div>
      </div>
      <div style="display: flex; justify-content: space-between;align-items: center ;flex-wrap: wrap;padding: 20px;background-color: white;margin-top: 20px">
        <div class="form-item" style="flex: 1">
          <div class="flex">
            <search-box #searchBox (doSearch)="doSearch($event)"
              [placeHolder]="'Search by Financial Job or Route'" [searchKeyword]="searchKeyword"
              style="min-width: 200px; width: 100%;"></search-box>
          </div>
        </div>
        <div class="form-item left10" >
          <div>
            <button nz-button nzType="primary" (click)="openQuickUploadPOD()">Quick upload PODs</button>
          </div>
        </div>
      </div>
    </div>
  </form>
    